<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <!-- <form action="#" @submit.prevent="submit"> -->
        <form action="#" @submit.prevent="showModal({ type: 'submit' })">
          <div class="form-body">
            <!-- <h3 class="card-title">Tindak Lanjut Surat</h3>
            <hr /> -->

            <div class="form-body">
              <h3 class="box-title">Pemesanan Ruangan</h3>
              <hr class="m-t-0 m-b-40" />

              <div v-if="state.files != '-'" class="col-md-12">Gambar Ruangan</div>
              <div v-if="state.files != '-'" class="col-md-12 pull-left">
                <table class="table table-striped">
                  <tr>
                    <th width="35%">Gambar</th>
                    <th width="15%" class="text-center">Aksi</th>
                  </tr>
                  <span
                    v-if="state.files.length != 0"
                  ></span>
                  <tr
                    v-else-if="!state.files.length"
                    class="text-center"
                  >
                    <td colspan="4">
                      <i>File tidak ada</i>
                    </td>
                  </tr>
                  <tr
                    v-for="file in state.files"
                    v-bind:key="file.filename"
                    style="margin: 5px"
                  >
                    <td>{{ file.filename }}</td>
                    <td class="text-center">
                      <a
                        class="btn btn-info text-white"
                        @click="prevDoc(file.uuid)"
                        title="View File"
                        ><i class="fa fa-eye text-white"></i
                      ></a>
                    </td>
                  </tr>
                </table>
              </div>
              <hr>

              <div class="row">
                <div class="col-md-8">
                  <Input 
                    v-model="payload.nama_kegiatan" 
                    name="nama_kegiatan"
                    type="text"
                    label="Nama Kegiatan"
                   />
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <Input
                    v-model="payload.tanggal_kegiatan"
                    name="tanggal_kegiatan"
                    type="date"
                    label="Tanggal Kegiatan"
                   />
                </div>
              </div>

              <div class="row" v-if="state.detail == 0">
                <div class="col-md-8">
                  <div class="form-group row">
                    <label
                      :class="[
                        'control-label',
                        { 'text-right': !isMobile },
                        'col-md-3',
                      ]"
                      >Waktu Kegiatan
                    </label>
                    <div :class="['col-md-9']">
                      <select
                        class="form-control custom-select"
                        v-model="payload.waktu_kegiatan"
                        label="Waktu Kegiatan"
                      >
                        <option value>Pilih Waktu Kegiatan</option>
                        <option value="8 - 12">8 - 12</option>
                        <option value="13 - 17">13 - 17</option>
                        <option value="8 - 17">8 - 17</option>
                      </select>
                    </div>
                    
                  </div>                  
                </div>
              </div>
              
              <div class="row" v-if="state.detail == 1">
                <div class="col-md-8">
                  <Input
                    v-model="payload.waktu_mulai"
                    name="waktu_mulai"
                    type="time"
                    label="Waktu Mulai"
                   />
                </div>
              </div>

              <div class="row" v-if="state.detail == 1">
                <div class="col-md-8">
                  <Input
                    v-model="payload.waktu_akhir"
                    name="waktu_selesai"
                    type="time"
                    label="Waktu Selesai"
                   />
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <Input 
                    v-model="payload.jumlah_peserta"
                    name="jumlah_peserta"
                    type="text"
                    label="Jumlah Peserta"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <Input 
                    v-model="payload.nama_penanggungjawab"
                    name="nama_penanggungjawab"
                    type="text"
                    label="Nama Penanggungjawab"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <Input 
                    v-model="payload.kontak_person"
                    name="kontak_person"
                    type="text"
                    label="Kontak Person"
                  />
                </div>
              </div>

              <!-- <div class="row"> -->
                <h3 class="box-title">Catatan / Keterangan</h3>
                <hr class="m-t-0 m-b-40" />

                <div class="row">
                  <div class="col-md-12">
                    <wysiwyg v-model="myHTML" />
                  </div>
                </div>
                <br />
              <!-- </div> -->
              

            </div>
          </div>

          <hr class="m-t-40" />

          <div class="form-actions">
            <button
              type="button"
              @click="showModal({ type: 'submit' })"
              class="btn btn-success"
              title="Submit"
            >
              Submit</button
            >&nbsp;
            <button
              type="button"
              @click="showModal({ type: 'cancel' })"
              class="btn btn-inverse"
              title="Cancel"
            >
              Cancel
            </button>
          </div>
        </form>
        <ModalDefault
          v-show="isVisible"
          :title="modalTitle"
          :content="modalContent"
          :action="ActionChangeable"
          :confirmation="isConfirmation"
          @close="onClose"
        />
      </div>
    </div>

    <hr />
    <!-- <div class="card">
      <h4>Keterangan</h4>
      <table>
        <tr>
          <td>Konfirmasi</td>
          <td>Untuk mengkonfirmasi surat</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>Retur</td>
          <td>Untuk meretur surat</td>
        </tr>
      </table>
    </div> -->
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import Modal from "@/components/modal/modal.vue";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    Modal,
    Input,
    Datepicker,
    RotateSquare5,
    Upload,
    Multiselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.meeting;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isMeeting() {
      return true;
    },
    items() {
      var data = [];
      if (this.$store.state.meeting.items) {
        for (
          let index = 0;
          index < this.$store.state.meeting.items.items.length;
          index++
        ) {
          let element = this.$store.state.meeting.items.items[index];
          data[index] = {
            id: element.id,
          };
        }
      }
      return data;
    },
  },
  data() {
    const uploader = this.fineUploader(this, "meeting");

    return {
      uploader,
      //   files: [],
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      disabledDates: {
        to: new Date(Date.now()),
      },
      isModalVisible: false,
      payload: {
        // confirm: 1,
        nama_kegiatan: "",
        tanggal_kegiatan: "",
        waktu_mulai: "",
        waktu_akhir: "",
        waktu_kegiatan: "",
        jumlah_peserta: "",
        nama_penanggungjawab: "",
        kontak_person: "",
        myHTML: "",
      },
      myHTML: "",
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
      disabledButton: false,
    };
  },
  // async mounted() {
  //   this.initialize();
  //   // this.getAllReference();
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   if (!this.editMode) {
  //     this.payload.unitkerja = [{ value: user.unit_id, text: user.unit }];
  //   }

  //   console.log(this.payload.tanggal_surat);
  // },

  mounted() {
    this.detailLoad();
  },

  methods: {
    detailLoad() {
      const state = {
        loaded: false,
      };
      const user = localStorage.getItem("user");
      this.$store.commit("meeting/STATE", state);
      this.$store.dispatch("meeting/getMeetingRById", this.$route.params);
    },
    onClose() {
      this.isVisible = false;
    },

    // async ambilTanggal() {
    //   this.disabledDates.to = this.payload.tanggal_surat;
    // },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "submit") {
        this.modalTitle = "Simpan Data?";
        this.modalContent = "<p>Apakah anda yakin ingin menyimpan data?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.submit;
      } else if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    async initialize() {
      try {
          axios
            .get(`/meetingr/${this.$route.params.id}`)
            .then((response) => {
              const state = {
                loaded: true,
              };
              this.$store.commit("meeting/STATE", state);
              this.edit(response.khusus);
            });
        
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
        this.payload = {
          is_tipe: data.is_tipe,
      //     unitkerja: data.receiver,
      //     agenda: data.agenda_number,
      //     tanggal_surat: new Date(data.tanggal_surat),
      //     tanggal_diterima: new Date(data.tanggal_diterima),
      //     sifat: data.document_sifat,
      //     jenis: data.document_jenis,
      //     perihal: data.subject,
      //     nama: data.document_name,
      //     nomorsurat: data.nomorsurat,
      //     pages: data.pages,
      //     myHTML: data.description,
      //     files: data.files,
      //     jumlah: data.jumlah,
      //     lampiran: data.document_lampiran,
      //     asrat: data.document_asrat,
      //     kodrat: data.document_kodrat,
      //     tas: data.document_tas,
      //     kecrat: data.document_kecrat,
      //     ketrat: data.document_ketrat,
      //     redaksi: data.document_redaksi
        };
      //   this.files = data.files;
      //   this.dropdownSifatSurat.value = data.document_asrat;
      //   this.dropdownKecepatanSurat.value = data.document_kecrat;
      //   this.dropdownLampiran.value = data.document_lampiran;
      //   this.dropdownJenisSurat.value = data.document_jenis;
      //   this.dropdownTipeAsalSurat.value = data.document_tas;
      //   this.dropdownAsalSurat.value = data.document_asrat;
      //   this.dropdownKodeSurat.value = data.document_kodrat;
      //   this.dropdownKeteranganSurat.value = data.document_ketrat;
      //   this.dropdownRedaksi.value = data.document_redaksi;
      //   this.dropdownUnitKerja.value = data.receiver;
      //   this.myHTML = data.description;
    },
    goBack() {
      this.$router.push("/meeting");
    },
    prevDoc(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "meeting",
          id: this.$route.params.id,
          file: string,
        },
      });
      console.log(routeData);
      window.open(routeData.href, "_blank");
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onDelete() {
      this.isModalVisible = false;
    },
    openModal() {
      this.isModalVisible = true;
    },
    submit() {
      console.log(this);
      const payload = {
        // confirm: this.payload.confirm,
        nama_kegiatan: this.payload.nama_kegiatan,
        tanggal_kegiatan: this.payload.tanggal_kegiatan,
        waktu_kegiatan: this.payload.waktu_kegiatan,
        waktu_mulai: this.payload.waktu_mulai,
        waktu_akhir: this.payload.waktu_akhir,
        jumlah_peserta: this.payload.jumlah_peserta,
        nama_penanggungjawab: this.payload.nama_penanggungjawab,
        kontak_person: this.payload.kontak_person,
        keterangan: this.myHTML,
      };
      // const data = JSON.stringify(payload);
      const data = payload;
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("meeting/submitPesan", {
              data,
              id: this.$route.params.id,
            });
          } else {
            this.$store.dispatch("meeting/submitPesan", {
              data,
              id: this.$route.params.id,
            });
          }
        }
      });
    },
    clearAll() {
      this.payload.kepada = [];
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>
<style lang="scss">
.vdp-datepicker {
  &.fullscreen-when-on-mobile {
    @media (max-width: 767px) {
      position: static;
      .vdp-datepicker__calendar {
        position: fixed;
        top: 50%;
        left: 5%;
        margin-top: -25%;
        width: 90%;
      }
    }
  }
}
</style>
